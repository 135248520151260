import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import pic01 from '../assets/images/background-testing.png'
import pic02 from '../assets/images/background-consultation.png'
import pic03 from '../assets/images/about-us.png'
import Banner from '../components/Banner'
import Layout from '../components/layout'

const HomeIndex = () => {
  return (
    <Layout>
      <Helmet
        title="Kimitech Sdn Bhd"
        meta={[
          { name: 'description', content: 'Kimitech' },
          { name: 'keywords', content: 'biotech' },
        ]}
      ></Helmet>

      <Banner />

      <div id="main">
        <section id="one" className="tiles">
          <article style={{ backgroundImage: `url(${pic01})` }}>
            <header className="major">
              <h3>TESTING</h3>
              <p>
                Kimitech International currently performs various qualification
                tests. We also offer several other non-certified tests and can
                set up your own test application based on customer requirements.
                Our experts ensure that application performance and integrity
                are maintained.
              </p>
              <ul className="actions">
                <li>
                  <a
                    href="/testing"
                    name="Find out more about testing service"
                    className="button next scrolly"
                  >
                    FIND OUT MORE
                  </a>
                </li>
              </ul>
            </header>
          </article>
          <article style={{ backgroundImage: `url(${pic02})` }}>
            <header className="major">
              <h3>CONSULTING</h3>
              <p>
                We provide consultation on product development, performance,
                application and improvement. Consulting work, often in
                conjunction with laboratory work, ranges from property and
                performance reporting and evaluation to advanced concept and
                product design discussions for a wide range of materials.
              </p>
              <ul className="actions">
                <li>
                  <a
                    href="/consulting"
                    name="Contact Us"
                    className="button next scrolly"
                  >
                    FIND OUT MORE
                  </a>
                </li>
              </ul>
            </header>
          </article>
          <article style={{ backgroundImage: `url(${pic03})` }}>
            <header className="major">
              <Link to="/about_us">
                <h3 style={{ margin: 0 }}>ABOUT US</h3>
              </Link>
              <p>
                Kimitech International aim to be Malaysia's premier testing
                laboratory. Providing accurate, high-quality testing and
                monitoring services to large corporate and walk-in customers. We
                are staffed by a team of qualified scientists and engineers who
                are highly experienced and dedicated to conducting analyses
                using state-of-the-art equipment and infrastructure.
              </p>
            </header>
          </article>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
